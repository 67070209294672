<template>
    <div>
        
        <div v-if="!$auth.loading" class="max-w-7xl px-4 mt-12 sm:px-6">
        <h1 class="text-2xl font-semibold text-gray-900 my-5">
            {{ $auth.isAuthenticated ? "Hey, " + $auth.user.name : "" }}
        </h1> 

        <!--Hook 1 end -->

        <div v-if="!disable_video">
            <div  class="max-w-3xl" >
                <div v-if="!hide_onboarding_video">
                    <div class="grid lg:justify-end">
                        <button class="cta bg-gp_pink-default-500 mb-4 justify-self-stretch" @click="dismissVideo();">Hide video</button>
                    </div>
                    <div class="mb-4" style="padding:56.25% 0 0 0;position:relative;" >
                        <iframe src="https://player.vimeo.com/video/748276126?h=6e5cced4ff&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="The Booking Process"></iframe></div>
                </div>
            </div>
        </div>  

        <InlineNotification class="!m-0" DismissId="ArtistDash">
			<template slot="body">

            <DictionaryContent :hookId="1" :dynamicUrl="'artist'">
                <h2 class="text-lg font-semibold !text-white  mb-4">Booking gigs with GigPig. Here’s how it’s done.</h2>
            </DictionaryContent>
                <DictionaryContent :hookId="2" :dynamicUrl="'artist'">
                    <ul class="artist_dash_instructions">
                        <li>A venue asks for your availability for a gig. You’ll find it in ‘New available gigs’.</li>
                        <li>Hit the ‘thumbs up’ to tell them you're free. Hit the ‘thumbs down’ if it’s not for you.</li>
                        <li>If the venue wants to book you, you’ll get a notification.</li>
                        <li>Play the gig. Bring the house down.</li>
                        <li>The venue signs off your invoice. You can download it from ‘Invoices’ too.</li>
                        <li>You get paid. No messing.</li> 
                    </ul>
                </DictionaryContent>  
            </template>
        </InlineNotification>
        

        <div class="mt-4" v-if="!this.artists || this.artists.length == 0">
            <p >We’re going on tour, but it’ll only take two ticks – let’s take you around the GigPig dashboard. </p>
            
        
            <div class="max-w-7xl " v-if="$auth.isAuthenticated">
                <!-- Replace with your content -->
                <div class="py-4">
                    <div class="dashboard">
                        <complete-artist-steps 
                        :has_artists="this.artists && this.artists.length > 0"
                        :has_offers="this.artistbids && this.artistbids.length > 0"
                        :has_bookings="this.artistbids && this.artistbids.filter((bid) => bid.artist_final_accepted).length > 0"
                        :has_raised_invoice="this.artistbids && this.artistbids.filter((bid) => bid.invoice_date_raised).length > 0"
                        />
                    </div>
                </div>
            </div>
            <!-- /End replace -->
        </div> 
        <div class="py-4" v-else>
            <h2 class="font-semibold">
                Notifications
            </h2>


            <div class="mt-4 grid grid-flow-row lg:grid-flow-col gap-4">

                    <AlertWithLink color_class="blue" :text="'You have unread messages: ' + $offer.gig.name + ' on ' + format($offer.gig.start)"  :to="'/gigs/' + $offer.gig.id + '/invoices'" v-for="($message, index) in bidsWithUnreadMessages" v-bind:key="'message' + index">
                        <template v-slot:icon> 
                        </template>
                    </AlertWithLink>

                    <AlertWithLink v-if="notificationsCount !== null && notificationsCount > 0" color_class="blue" text="There are new gigs to give your availability for.  Click here to give the venue your availability for the gigs" :to="'/artist/offers'" >
                        <template v-slot:icon>
                            <ReceiptTaxIcon />
                        </template>
                    </AlertWithLink>

                    <div v-if="notificationsCount !== null && notificationsCount === 0">
                        <p>You're all up to date, there are no new notifications.</p>
                    </div> 
                </div>           
            </div>            

            <div v-if="hide_onboarding_video && !disable_video">
                <div class="grid lg:justify-end mt-8">
                <a class="text-gp_pink-default-500" @click="showVideo">View introduction video</a>
                </div>
            </div>


        </div>
      

        
    </div>
</template>

<style scoped>

.artist_dash_instructions ul{
    @apply py-4;
}
    .artist_dash_instructions li{
        list-style:disc;
        list-style-position:inside;
    }

</style>

<script>

import { ReceiptTaxIcon } from '@vue-hero-icons/outline'
import AlertWithLink from '../../components/alert/AlertWithLink.vue'
import { apiMethods, apiComputed } from '@/state/helpers'
import CompleteArtistSteps from '../../components/dashboard/CompleteArtistSteps.vue'
import DictionaryContent from '../../components/dictionary/DictionaryContent.vue';
import InlineNotification from '../../components/notifications/inline.vue';

const video_seen_key = 'artist_onboard_video_seen';
export default {
    components: 
    { 
        CompleteArtistSteps, AlertWithLink, ReceiptTaxIcon, 
        DictionaryContent, InlineNotification
    },
    name: "Dashboard",
    methods:{
        ...apiMethods,
        dismissVideo(){
            window.localStorage.setItem(video_seen_key, true);
            this.hide_onboarding_video = true;
        },
        showVideo(){
            window.localStorage.removeItem(video_seen_key);
            this.hide_onboarding_video = false; 
        }
    },
  watch: {
    user() {
      this.getUserNotificationsCount(this.user.id).then(response => {
        this.notificationsCount = response.notifications_count
      })

      const domainBrand = this.domainBrand;

      this.disable_video = this.user && this.user.referral_code == 'genre' || domainBrand;

      this.hide_referral_message = this.user && this.user.referral_code == 'genre' || domainBrand;
    }
  },
    computed:{
        ...apiComputed,
        domainBrand(){
            return this.$store.getters['api/domainBrand']
        },
    }, 
    data(){
        return {
            notificationsCount: null,
            bidsWithUnreadMessages: [],
            hide_onboarding_video: false,
            disable_video: false,
            hide_referral_message: true,
            user: null
        }
    },
    mounted(){

      this.$store.dispatch('user/getUsersData').then(response => {
        this.user = response
      })

        let enableArtist = process.env.VUE_APP_ENABLE_ARTIST_FUNCTIONALITY;
            
        if (enableArtist === "false"){
            this.$router.push('/artist/profiles');
        }
 
        this.bidsWithUnreadMessages = this.getArtistUnreadMessages();

        
        let vimeoScript = document.createElement('script')
        vimeoScript.setAttribute('src', 'https://player.vimeo.com/api/player.js')
        document.head.appendChild(vimeoScript);

        if (window.localStorage[video_seen_key]) {
            this.hide_onboarding_video = true;
        }
    },

}
</script>